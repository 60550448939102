import React from 'react'
import Navbar from '../Components/Navbar'
import GraficoDispersao from '../Components/GraficoDispersao'

export default function GraficoDispersaoPage() {
  return (
    <Navbar page={<GraficoDispersao />}>
    </Navbar>
  )
}
