import React, { useEffect, useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ZAxis } from 'recharts';
import axios from 'axios';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';

const RenderChart = ({ data }) => {
  return (
    <ResponsiveContainer width="80%" height={400}>
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20
        }}
      >
        <CartesianGrid />
        <XAxis 
          type="category" 
          dataKey="x" 
          name="Ano ingresso" 
          allowDuplicatedCategory={false} 
          label={{ value: 'Ano de Ingresso', position: 'insideBottom', offset: -5 }}
        />
        <YAxis 
          type="number" 
          dataKey="y" 
          name="Média" 
          label={{ value: 'Média', angle: -90, position: 'insideLeft', dy: -10, dx: -10 }}
        />
        <ZAxis 
          type="number" 
          dataKey="z" 
          name="Carga horária cursada"
          range={[0, 200]}
          label={{ value: 'Carga Horária Cursada', position: 'top', offset: 10 }}
        />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Scatter name="Dados de exemplo" data={data} fill="#8884d8" />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

const GraficoBolha = () => {
  const [chartData, setChartData] = useState([]);
  const [curso, setCurso] = useState({ nome: 'cc', valor: 1 });
  const [anoInicio, setAnoInicio] = useState('2018.1');
  const [anoFim, setAnoFim] = useState('2021.1');
  const [materia, setMateria] = useState('pf');
  const [materias, setMaterias] = useState([]);
  const anos = ['2012.1', '2013.1', '2014.1', '2015.1', '2016.1', '2017.1', '2018.1', '2019.1', '2020.1', '2021.1'];
  const cursos = [{ nome: 'cc', valor: 1 }, { nome: 'ec', valor: 2 }, { nome: 'si', valor: 3 }];

  useEffect(() => {
    setChartData([]);
  }, [curso]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://icausas.masu.email/api/teste_student/student/bolha/${curso.valor}/${anoInicio}/${anoFim}/`);
        const adaptedData = response.data.map(item => ({
          x: item.initial_period,
          y: item.media,
          z: item.tam_bolha,
        }));
        setChartData(adaptedData);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error.message);
      }
    };
    fetchData();
  }, [anoInicio, anoFim, curso, materia]);

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Curso</InputLabel>
          <Select
            value={curso}
            onChange={e => setCurso(e.target.value)}
            label="Curso"
            renderValue={(selected) => selected.nome}
          >
            {cursos.map((item, i) => (
              <MenuItem key={i} value={item}>{item.nome}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Início</InputLabel>
          <Select value={anoInicio} onChange={e => setAnoInicio(e.target.value)} label="Início">
            {anos.map((item, i) => (
              <MenuItem key={i} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Fim</InputLabel>
          <Select value={anoFim} onChange={e => setAnoFim(e.target.value)} label="Fim">
            {anos
              .filter(item => item >= anoInicio)
              .map((item, i) => (
                <MenuItem key={i} value={item}>{item}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {`Comparação da Média de Desempenho por Ano de Ingresso e Carga Horária para o Curso de ${curso.nome.toUpperCase()} entre ${anoInicio} e ${anoFim}`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <RenderChart data={chartData} />
      </Box>
    </div>
  );
};

export default GraficoBolha;
