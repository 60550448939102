import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCEC-OuGcix9G79fBKo-nSDHHuwH9GTaZY",
  authDomain: "icausas-5aacd.firebaseapp.com",
  projectId: "icausas-5aacd",
  storageBucket: "icausas-5aacd.appspot.com",
  messagingSenderId: "308100767538",
  appId: "1:308100767538:web:0d4a5ce100633a75d9c12b",
  measurementId: "G-GKDB22HRZW"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export default app;