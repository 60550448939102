import React from 'react'
import Navbar from '../Components/Navbar'
import Questionario from '../Components/Questionario'

export default function QuestionarioPage() {
  return (
    <div>
        <Navbar page={<Questionario></Questionario>}></Navbar>
    </div>
  )
}
