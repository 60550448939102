import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';

const Conta = () => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '50px 0',
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        maxWidth: '600px', // Limitar a largura do formulário para que ele não fique muito largo
        width: '100%', // Para garantir que o formulário ocupe todo o contêiner
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-200px'
    };


    const leftColumnStyle = {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '50px', // Adicionando margem à direita
    };

    const rightColumnStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        border: 'none',
        borderBottom: '1px solid #ccc',
        fontSize: '16px',
    };

    const labelStyle = {
        marginBottom: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#333',
    };

    const radioStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '10px 0',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
    };

    const passwordStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 0',
    };

    const buttonStyle = {
        backgroundColor: '#1c2f59',
        color: '#FFFFFF',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        fontSize: '16px',
        cursor: 'pointer',
    };


    const [nome, setNome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [sexo, setSexo] = useState('');
    const [senhaAtual, setSenhaAtual] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(
            `Nome: ${nome}, Data de Nascimento: ${dataNascimento}, Sexo: ${sexo}, Senha Atual: ${senhaAtual}, Nova Senha: ${novaSenha}, Confirmar Senha: ${confirmarSenha}`,
        );
    };

    return (
        <div style={containerStyle}>

            <form onSubmit={handleSubmit} style={formStyle}>
                <div style={leftColumnStyle} >
                    <Avatar style={{ width: '70px', height: '70px', borderRadius: '50%', backgroundColor: 'blue' }}>
                        <PersonIcon style={{ fontSize: '50px' }} />
                    </Avatar>

                    <input
                        placeholder='Nome completo'
                        type="text"
                        id="nome"
                        name="nome"
                        style={inputStyle}
                        value={nome}
                        onChange={(event) => setNome(event.target.value)}
                    />

                    <input
                        type="date"
                        id="dataNascimento"
                        name="dataNascimento"
                        style={inputStyle}
                        value={dataNascimento}
                        onChange={(event) => setDataNascimento(event.target.value)}
                    />
                    <div style={radioStyle}>
                        <span style={{ marginBottom: '10px' }}>Sexo</span>
                        <div>
                            <input
                                type="radio"
                                id="masculino"
                                name="sexo"
                                value="masculino"
                                checked={sexo === 'masculino'}
                                onChange={(event) => setSexo(event.target.value)}
                            />
                            <label htmlFor="masculino">Masculino</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="feminino"
                                name="sexo"
                                value="feminino"
                                checked={sexo === 'feminino'}
                                onChange={(event) => setSexo(event.target.value)}
                            />
                            <label htmlFor="feminino">Feminino</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="outro"
                                name="sexo"
                                value="outro"
                                checked={sexo === 'outro'}
                                onChange={(event) => setSexo(event.target.value)}
                            />
                            <label htmlFor="outro">Outro</label>
                        </div>
                    </div>
                </div>
                <div style={rightColumnStyle}>
                    <label htmlFor="dataNascimento" style={labelStyle}>
                        Redefinir senha
                    </label>
                    <div style={passwordStyle}>
                        <input
                            placeholder='Senha atual'
                            type="password"
                            id="senhaAtual"
                            name="senhaAtual"
                            style={inputStyle}
                            value={senhaAtual}
                            onChange={(event) => setSenhaAtual(event.target.value)}
                        />
                    </div>
                    <div style={passwordStyle}>
                        <input
                            placeholder='Nova senha'
                            type="password"
                            id="novaSenha"
                            name="novaSenha"
                            style={inputStyle}
                            value={novaSenha}
                            onChange={(event) => setNovaSenha(event.target.value)}
                        />
                    </div>
                    <div style={passwordStyle}>
                        <input
                            placeholder='Confirmar Senha'
                            type="password"
                            id="confirmarSenha"
                            name="confirmarSenha"
                            style={inputStyle}
                            value={confirmarSenha}
                            onChange={(event) => setConfirmarSenha(event.target.value)}
                        />
                    </div>
                    <button type="submit" style={buttonStyle}>
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Conta;
