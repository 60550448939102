import React from 'react'
import Navbar from '../Components/Navbar'
import ViolinPlot from '../Components/ViolinPlot'

export default function ViolinPlotPage() {
  return (
    <Navbar page={<ViolinPlot />}>
    </Navbar>
  )
}
