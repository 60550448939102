import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DialogCadastro from '../DialogCadastro';
import './entrar.css';
import logo from './img/log.svg';
import register from './img/register.svg';
import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';

export default function EntrarCadastro() {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [showDialog, setShowDialog] = useState(false); 

  const navigate = useNavigate();

  const handleSignUpClick = () => {
    setIsSignUpMode(true);
  };

  const handleSignInClick = () => {
    setIsSignUpMode(false);
  };

  function handleEmailSignUp(event) {
    event.preventDefault();
    setShowDialog(true);

    const email = event.target.email.value;
    const password = event.target.password.value;

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //navigate('/Pages/Home');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleEmailSignIn(event) {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        navigate('/home');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleGoogleLogin() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        if (!emailValido(result.user.email)) {
          auth.signOut();
        } else {
          navigate('/home');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function emailValido(email) {
    const permitidos = ['@academico.ufs.br', 'dcomp.ufs.br'];
    const dominioEmail = email.split('@')[1];
    return permitidos.includes(dominioEmail);
  }

  return (
    <>
    <div className={`container ${isSignUpMode ? 'sign-up-mode' : ''}`}>
      <div className="forms-container">
        <div className="signin-signup">
          <form action="#" className="sign-in-form" onSubmit={handleEmailSignIn}>
            <h2 className="title">Entrar</h2>
            <div className="input-field">
              <i><EmailIcon/></i>
              <input type="email" placeholder="email" name="email" />
            </div>
            <div className="input-field">
              <i><LockIcon/></i>
              <input type="password" placeholder="senha" name="password" />
            </div>
            <button type="submit" className="btn solid">
              Entrar
            </button>
            <p className="social-text">Esqueceu a senha?</p>
            <div className="social-media">
              <a href="#" className="social-icon" onClick={handleGoogleLogin}>
              <GoogleIcon />
              </a>
            </div>
          </form>
          <form action="#" className="sign-up-form" onSubmit={handleEmailSignUp}>
            <h2 className="title">Cadastre-se</h2>
            <div className="input-field">
              <i><EmailIcon/></i>
              <input type="email" placeholder="Email" name="email" />
            </div>
            <div className="input-field">
              <i><PersonIcon/></i>
              <input type="text" placeholder="Nome" name="name" />
            </div>

            <div className="input-field">
              <i><LockIcon/></i>
              <input type="password" placeholder="Senha" name="password" />
            </div>
            {showDialog && <DialogCadastro />}

            <input type="submit" className="btn" value="Confirmar" />
            <p className="social-text">Ou cadastre-se com a sua conta google</p>
            <div className="social-media">
              <a href="#" className="social-icon" onClick={handleGoogleLogin}>
              <GoogleIcon />
              </a>
            </div>
          </form>
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>Novo por aqui?</h3>
            <p>
              Crie a sua conta
            </p>
            <button className="btn transparent" id="sign-up-btn" onClick={handleSignUpClick}>
              Cadastre-se
            </button>
          </div>
          <img src={logo} className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>Já tem conta?</h3>
            <p>
              Acesse a sua conta
            </p>
            <button className="btn transparent" id="sign-in-btn" onClick={handleSignInClick}>
              Entrar
            </button>
          </div>
          <img src={register} className="image" alt="" />
        </div>
      </div>
    </div>
    </> 
  );
}
