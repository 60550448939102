import React from 'react'
import Navbar from '../Components/Navbar'
import BoxPlot from '../Components/BoxPlot'

export default function BoxPlotPage() {
  return (
    <Navbar page={<BoxPlot />}>
    </Navbar>
  )
}
