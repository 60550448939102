import './App.css';
import Router from './Router';
import BoxPlotChart from './Components/BoxPlot';
import GraficoBolha from './Components/GraficoBolhas';

function App() {
  return <Router></Router>;
}

export default App;
