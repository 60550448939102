import React from 'react'
import Navbar from '../Components/Navbar'
import Grafico from '../Components/Grafico'

export default function GraficoPage() {
  return (
    <Navbar page={<Grafico />}>
    </Navbar>
  )
}
