import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

const options = {
  sankey: {
    iterations: 0,
    node: {
      nodePadding: 12,
      label: {
        fontSize: 14,
        color: "#424242",
        bold: true,
      },
      colors: ["#4CAF50", "#FF9800", "#F44336", "#03A9F4", "#9C27B0", "#3F51B5"],
    },
    link: {
      colorMode: "gradient",
      colors: ["#FF5722", "#673AB7", "#009688", "#FFC107", "#795548", "#9E9E9E", "#607D8B"],
      fillOpacity: 0.5,
    },
  },
};

const fixedCourses = ["SI", "CC", "EC", "Evasão"];
const anos = [
  '2013.1', '2013.2',
  '2014.1', '2014.2',
  '2015.1', '2015.2',
  '2016.1', '2016.2',
  '2017.1', '2017.2',
  '2018.1', '2018.2',
  '2019.1', '2019.2',
  '2020.1', '2020.2',
  '2021.1', '2021.2',
  '2022.1'
];

export function App() {
  const [data, setData] = useState([["De", "Para", "Peso"]]);
  const [anoInicio, setAnoInicio] = useState(anos[0]);
  const [anoFim, setAnoFim] = useState(anos[anos.length - 1]);

  useEffect(() => {
    fetch(`https://icausas.masu.email/api/teste_student/student/sankey/${anoInicio}/${anoFim}`)
      .then(response => response.json())
      .then(apiData => {
        const formattedData = [
          ["De", "Para", "Peso"],
          ...apiData.map(item => [
            item.course_name,
            item.period,
            item.number_of_students_active,
          ]),
          ...apiData
            .filter(item => item.number_of_students_dropout > 0)
            .map(item => [
              item.course_name,
              "Evasão",
              item.number_of_students_dropout,
            ]),
        ];

        setData(formattedData);
      })
      .catch(error => console.error('Error fetching Sankey data:', error));
  }, [anoInicio, anoFim]);

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="inicio-label">Início</InputLabel>
          <Select value={anoInicio} onChange={e => setAnoInicio(e.target.value)} label="Início" labelId="inicio-label">
            {anos.map((item, i) => (
              <MenuItem key={i} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="fim-label">Fim</InputLabel>
          <Select value={anoFim} onChange={e => setAnoFim(e.target.value)} label="Fim" labelId="fim-label">
            {anos
              .filter(item => item >= anoInicio)
              .map((item, i) => (
                <MenuItem key={i} value={item}>{item}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Typography variant="h6" align="center" gutterBottom>
        Fluxo de Alunos entre Cursos e Evasão por Período
      </Typography>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "20px" }}>
        <div style={{ flex: 1, minWidth: "1200px" }}> {/* Ajuste a largura mínima aqui */}
          <Chart
            chartType="Sankey"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        </div>
      </div>

    </div>
  );
}

export default App;
