import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import './entrar.css';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    maxWidth: '400px',
    width: '100%', 
  },
}));

export default function DialogCadastro() {
  const [open, setOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogContent>

          <h6 className="title">Cadastrado com sucesso</h6>

        </DialogContent>
        <DialogActions>
        <button className="btn solid" onClick={handleConfirm}>
            OK
          </button>

        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
