import React from 'react'
import Conta from '../Components/Conta'
import Navbar from '../Components/Navbar'

export default function ContaPage() {
  return (
    <div>
        <Navbar page={<Conta></Conta>}></Navbar>
    </div>
  )
}
