import React, { useState } from 'react';
import styles from './contato.module.css';

export default function Contato() {
  const [status, setStatus] = useState("idle");

  const handleSubmit = async (event) => {
    event.preventDefault();

    setStatus("pending");

    const form = event.target;
    const formData = new FormData(form);

    const response = await fetch("https://formspree.io/f/xyyabyde", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
      form.reset();
      setStatus("success");
    } else {
      setStatus("error");
    }
  };

  return (
    <div>
      <div className={styles['forms-container']}>
        <div className={styles['signin-signup']}>
          <form action="#" className={styles['sign-in-form']} onSubmit={handleSubmit}>
            <h2 className={styles['title']}>Contato</h2>
            <div className={styles['input-field']}>
              <i className="fas fa-user"></i>
              <input type="text" placeholder="Nome" name="name" />
            </div>
            <div className={styles['input-field']}>
              <i className="fas fa-lock"></i>
              <input type="email" placeholder="Email" name="email" />
            </div>
            <div className={styles['input-field2']}>
              <textarea placeholder="Texto" name="message"></textarea>
            </div>
            {status === "success" ? (
              <div>Mensagem enviada com sucesso!</div>
            ) : (
              <input type="submit" value="Enviar" className={'btn solid'} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
