import React from 'react'
import Navbar from '../Components/Navbar'
import SankeyDiagram from '../Components/SankeyDiagram'

export default function SankeyDiagramPage() {
  return (
    <Navbar page={<SankeyDiagram />}>
    </Navbar>
  )
}
