import React from 'react'
import Navbar from '../Components/Navbar'
import GraficoBolha from '../Components/GraficoBolhas'

export default function GraficoBolhaPage() {
  return (
    <Navbar page={<GraficoBolha />}>
    </Navbar>
  )
}
