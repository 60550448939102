import React from 'react'
import Navbar from '../Components/Navbar'
import GraficoBarra from '../Components/GraficoBarra'
import Grafico from '../Components/Grafico'

export default function GraficoBarraPage() {
  return (
    <Navbar page={<GraficoBarra />}>
    </Navbar>
  )
}
