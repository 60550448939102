import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import axios from 'axios';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [curso, setCurso] = useState({ nome: 'cc', valor: 1 });
  const [anoInicio, setAnoInicio] = useState('2018.2');
  const [anoFim, setAnoFim] = useState('2021.2');

  const anos = ['2018.2', '2019.1', '2019.2', '2020.1', '2020.2', '2021.1', '2021.2'];
  const cursos = [{ nome: 'cc', valor: 1 }, { nome: 'ec', valor: 2 }, { nome: 'si', valor: 3 }];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://icausas.masu.email/api/teste_student/student/violinplot/${curso.valor}/${anoInicio}/${anoFim}/`);
        const apiData = response.data;

        let x = [];
        let y = [];

        apiData.forEach(item => {
          item.avg_completion_time.forEach(time => {
            x.push(item.initial_period);
            y.push(time);
          });
        });

        const formattedData = [
          {
            type: 'violin',
            x: x,
            y: y,
            points: 'none',
            box: {
              visible: true
            },
            line: {
              color: 'black'
            },
            meanline: {
              visible: true
            }
          }
        ];

        setData(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar dados da API", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [curso, anoInicio, anoFim]);

  const layout = {
    yaxis: {
      zeroline: false,
      title: 'Tempo Médio de Conclusão (em anos)'
    },
    xaxis: {
      title: 'Período',
      type: 'category'
    }
  };

  return (
    <div className="App">
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="curso-label">Curso</InputLabel>
          <Select
            labelId="curso-label"
            value={curso}
            onChange={e => setCurso(e.target.value)}
            label="Curso"
            renderValue={(selected) => selected.nome}
          >
            {cursos.map((item, i) => (
              <MenuItem key={i} value={item}>{item.nome}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="ano-inicio-label">Início</InputLabel>
          <Select
            labelId="ano-inicio-label"
            value={anoInicio}
            onChange={e => setAnoInicio(e.target.value)}
            label="Início"
          >
            {anos.map((item, i) => (
              <MenuItem key={i} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="ano-fim-label">Fim</InputLabel>
          <Select
            labelId="ano-fim-label"
            value={anoFim}
            onChange={e => setAnoFim(e.target.value)}
            label="Fim"
          >
            {anos
              .filter(item => item >= anoInicio)
              .map((item, i) => (
                <MenuItem key={i} value={item}>{item}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Typography variant="h6" sx={{ mb: 0 }}>
          {`Distribuição do Tempo Médio de Conclusão por Período para o Curso de ${curso.nome.toUpperCase()} entre ${anoInicio} e ${anoFim}`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 0 }}>
        {loading ? (
          <p>Carregando...</p>
        ) : (
          <Plot
            data={data}
            layout={layout}
          />
        )}
      </Box>
    </div>
  );
}

export default App;
