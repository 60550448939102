import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../Pages/Login';
import Home from '../Pages/Home';
import GraficoPage from '../Pages/Grafico';
import ContatoPage from '../Pages/Contato';
import QuestionarioPage from '../Pages/Questionario';
import ContaPage from '../Pages/Conta';
import { theme } from '../Theme'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import GraficoBarraPage from '../Pages/GraficoBarra';
import GraficoDispersaoPage from '../Pages/GraficoDispersaoPage';
import BoxPlotPage from '../Pages/BoxPlot';
import GraficoBolhaPage from '../Pages/GraficoBolhaPage';
import ViolinPlotPage from '../Pages/ViolinPlot';
import SankeyDiagramPage from '../Pages/SankeyDiagram';

export default function Router() {
    return (
        <ThemeProvider theme={theme}>
                  <CssBaseline>

        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/home" element={<Home/>}/>
                <Route path="/grafico" element={<GraficoPage/>}/>
                <Route path="/contato" element={<ContatoPage/>}/>
                <Route path="/questionario" element={<QuestionarioPage/>}/>
                <Route path="/conta" element={<ContaPage/>}/>
                <Route path="/graficobarra" element={<GraficoBarraPage/>}/>
                <Route path="/graficodispersao" element={<GraficoDispersaoPage/>}/>
                <Route path="/graficobolha" element={<GraficoBolhaPage/>}/>
                <Route path="/boxplot" element={<BoxPlotPage/>}/>
                <Route path="/violinplot" element={<ViolinPlotPage/>}/>
                <Route path="/sankeydiagram" element={<SankeyDiagramPage/>}/>



            </Routes>
        </BrowserRouter>
        </CssBaseline>

        </ThemeProvider>

    )
}
