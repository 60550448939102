import React from 'react';

export default function Questionario() {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100vh',
    backgroundColor: 'white',
  };

  const wrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const rowStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const rectStyle = {
    width: '300px',
    height: '200px',
    margin: '30px',
    backgroundColor: '#2a4b6e',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative', // adicionado
    color: 'white',
  };

  const headerStyle = {
    backgroundColor: '#101d4a',
    height: '30px',
    color: '#fff',
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    paddingTop: '5px',
    width: '100%',
    boxSizing: 'border-box',
    position: 'absolute', // adicionado
    top: 0, // adicionado
    color: 'white'
  };

  return (
    <div style={containerStyle}>
      <div style={wrapperStyle}>
        <h1 style={{ textAlign: 'center', marginTop: 0 }}>Tarefas pendentes</h1>
        <div style={rowStyle}>
          <div style={rectStyle}>
            <div style={headerStyle}></div>
            <div style={{ padding: '10px' }}>Questionário 1</div>
          </div>
          <div style={rectStyle}>
            <div style={headerStyle}></div>
            <div style={{ padding: '10px' }}>Questionário 2</div>
          </div>
          <div style={rectStyle}>
            <div style={headerStyle}></div>
            <div style={{ padding: '10px' }}>Questionário 3</div>
          </div>
        </div>
      </div>
    </div>
  );
}